import { Moment } from 'moment';
import { User, UserType, UserTypeString } from '@/models/user';

const tableColumns = [
  {
    name: 'id',
    required: true,
    label: 'Código',
    align: 'left',
    field: (row: User): number | null => row.id,
    sortable: true,
  },
  {
    name: 'name',
    required: true,
    label: 'Nome',
    align: 'left',
    field: (row: User): string => row.name,
    sortable: true,
  },
  {
    name: 'document',
    required: true,
    label: 'Documento',
    align: 'left',
    field: (row: User): string => row.document,
    format: (val: string): string => (val.length > 20 ? `${val.substring(0, 20)}...` : val),
    sortable: true,
  },
  {
    name: 'phone',
    required: true,
    label: 'Telefone',
    align: 'left',
    field: (row: User): string => row.phone,
    sortable: true,
  },
  {
    name: 'email',
    required: true,
    label: 'Email',
    align: 'left',
    field: (row: User): string => row.email,
    sortable: true,
  },
  {
    name: 'type',
    required: true,
    label: 'Tipo',
    align: 'left',
    field: (row: User): UserType => row.type,
    format: (val: UserType): string => UserTypeString[val],
    sortable: true,
  },
  {
    name: 'deletedAt',
    required: true,
    label: 'Desabilitado',
    align: 'left',
    field: (row: User): Moment | null => row.deletedAt,
    format: (val: Moment | null): string => (val !== null ? `Sim, em ${val.format('L')}` : 'Não'),
    sortable: true,
  },
  {
    name: 'actions',
    label: '',
    align: 'left',
    field: (row: User): User => row,
    sortable: false,
  },
];

export { tableColumns };
