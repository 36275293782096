import { User, UserType, UserTypeString } from '@/models/user';

interface UserForm {
  name: string;
  region: number | null;
  regional: number | null;
  email: string;
  document: string;
  type: UserType | null;
  phone: string;
  supervisorId: number | null;
  password: string;
  passwordConfirm: string;
}

interface UserFormToRequest {
  name: string;
  region: number | null;
  regional: number | null;
  email: string;
  document: string;
  type: UserType | null;
  phone: string;
  supervisor_id: number | null;
  password: string;
  password_confirmation: string;
}

interface UserTypeOptions {
  label: string;
  value: UserType;
}

const getEmptyUserForm = (): UserForm => ({
  name: '',
  region: null,
  regional: null,
  email: '',
  document: '',
  type: null,
  phone: '',
  supervisorId: null,
  password: '',
  passwordConfirm: '',
});

const getFormByInstance = (user: User): UserForm => ({
  name: user.name,
  region: user.region,
  regional: user.regional,
  email: user.email,
  document: user.document,
  type: user.type,
  phone: user.phone,
  supervisorId: user.supervisorId,
  password: '',
  passwordConfirm: '',
});

const toRequestData = (formData: UserForm): UserFormToRequest => {
  return {
    name: formData.name,
    region: formData.region,
    regional: formData.regional,
    email: formData.email,
    document: formData.document,
    type: formData.type,
    phone: formData.phone,
    supervisor_id: formData.supervisorId,
    password: formData.password,
    password_confirmation: formData.passwordConfirm,
  };
};

const getUserTypeOptions = (): UserTypeOptions[] =>
  Object.keys(UserTypeString).map((key) => ({
    label: UserTypeString[key as UserType],
    value: key as UserType,
  }));

export { getEmptyUserForm, getFormByInstance, getUserTypeOptions, toRequestData, UserForm };
